import { defineStore } from "pinia";
import { useAuthStore } from "./authStore";
import { useActivityStore } from "./activityStore";
import axios from "axios";

export const useTicketStore = defineStore("ticketStore", {
  state: () => ({
    tickets: [],
    isLoading: false,
    error: null
  }),

  actions: {
    async fetchTicketsFromActivities() {
      const apiUrl = process.env.VUE_APP_API_ENDPOINT;
      const authStore = useAuthStore();
      const activityStore = useActivityStore();

      if (!authStore.token) {
        throw new Error("Authentication token is not available.");
      }

      try {
        this.isLoading = true;
        this.tickets = []; // Reset tickets

        // **Extract issue IDs from activityStore**
        const issueIds = Object.keys(activityStore.activitiesByIssue);
        if (issueIds.length === 0) {
          console.warn("No Issue IDs found in activities.");
          this.isLoading = false;
          return;
        }

        console.log("Fetching tickets for Issues:", issueIds.join(","));

        // **Fetch tickets based on extracted issue IDs**
        const response = await axios.get(`${apiUrl}/issues`, {
          params: { issueId: issueIds.join(",") }, // No date filtering
          headers: {
            Authorization: `Bearer ${authStore.token}`
          }
        });

        let tickets = response.data;

        // ✅ **Replace `billable_Seconds` with `totalBillable` from activities**
        tickets = tickets.map(ticket => {
          const issueId = ticket.id; // ID of the ticket
          const activityData = activityStore.activitiesByIssue[issueId]; // Get activity data

          if (activityData) {
            ticket.billable_Seconds = Math.round(activityData.totalBillable * 3600); // Convert hours to seconds
          } else {
            ticket.billable_Seconds = 0; // Default to 0 if no matching data
          }

          return ticket;
        });

        this.tickets = tickets;
        console.log("Updated Tickets with Activity Data:", this.tickets);

        this.error = null;
      } catch (err) {
        console.error("Error fetching tickets:", err);
        this.error = err.message;
        this.tickets = [];
      } finally {
        this.isLoading = false;
      }
    },

    clearTickets() {
      console.log("Clearing ticket data.");
      this.tickets = [];
    }
  },

  getters: {
    getTicketsByIssueId: (state) => (issueId) => {
      return state.tickets.filter(ticket => ticket.issueId === issueId);
    }
  }
});
