import { defineStore } from "pinia";
import { useAuthStore } from "./authStore";
import { usePeriodStore } from "./periodStore";
import { useMilestoneStore } from "./milestoneStore";
import { useMyStore } from "./store";
import axios from "axios";

export const useActivityStore = defineStore("activityStore", {
  state: () => ({
    activities: [],
    activitiesByIssue: {}, // Store activities grouped by Issue ID
    activitiesByJob: {}, // Store activities grouped by Job ID
    activitiesByMilestone: {}, // Store activities grouped by Milestone ID
    isLoading: false,
    error: null
  }),

  actions: {
    clearActivities() {
        console.log("Clearing all activities and resetting store...");
        const myStore = useMyStore();
        this.activities = [];
        this.activitiesByIssue = {};
        this.activitiesByJob = {};
        this.activitiesByMilestone = {};
        this.error = null;
        myStore.isLoading = false;
    },
    async fetchActivitiesFromPeriods() {
        const myStore = useMyStore();
        if (this.activities.length > 0) {
          console.log("Activities already fetched, skipping API call.");
          return;
        }
      
        myStore.isLoading = true;
      
        try {
          const apiUrl = process.env.VUE_APP_API_ENDPOINT;
          const authStore = useAuthStore();
          const periodStore = usePeriodStore();
      
          console.log("Fetching activities from middleware API...");
          const UTCStartDate = myStore.UTCStartDate;
          const UTCEndDate = myStore.UTCEndDate;
      
          const jobIds = new Set();
          const issueIds = new Set();
          const taskIds = new Set();
          const milestoneIds = new Set();
      
          for (const period of periodStore.periods) {
            for (const allocation of period.timeAllocations) {
              const { against_Type, against_Id } = allocation;
              if (against_Type === "job") jobIds.add(against_Id);
              if (against_Type === "issue") issueIds.add(against_Id);
              if (against_Type === "task") taskIds.add(against_Id);
              if (against_Type === "milestone") milestoneIds.add(against_Id);
            }
          }
      
          const fetchActivities = async (type, ids) => {
            if (ids.size === 0) return;
            try {
              console.log(`Fetching activities for ${type}s:`, [...ids].join(","));
              const response = await axios.get(`${apiUrl}/tasks`, {
                params: {
                  againstType: type,
                  againstId: [...ids].join(","),
                  date_modified_after: UTCStartDate,
                  date_modified_before: UTCEndDate
                },
                headers: { Authorization: `Bearer ${authStore.token}` }
              });
      
              this.activities.push(...response.data);
            } catch (error) {
              console.error(`Error fetching activities for ${type}s`, error);
            }
          };
      
          await Promise.all([
            fetchActivities("job", jobIds),
            fetchActivities("issue", issueIds),
            fetchActivities("milestone", milestoneIds)
          ]);
      
          for (const taskId of taskIds) {
            try {
              console.log(`Fetching task activity for Task ID: ${taskId}`);
              const response = await axios.get(`${apiUrl}/tasks`, {
                params: {
                  againstType: "task",
                  againstId: taskId,
                  date_modified_after: UTCStartDate,
                  date_modified_before: UTCEndDate
                },
                headers: { Authorization: `Bearer ${authStore.token}` }
              });
      
              this.activities.push(response.data);
            } catch (error) {
              console.error(`Error fetching activities for Task ID: ${taskId}`, error);
            }
          }
      
          this.processActivities();
          this.error = null;
        } catch (err) {
          console.error("Error fetching activities from middleware:", err);
          this.error = err.message;
          this.activities = [];
        } finally {
          myStore.isLoading = false;
        }
      },
      

      processActivities() {
        const issueActivities = {}; // Store grouped issue activities
        const jobActivities = {}; // Store grouped job activities
        const milestoneActivities = {}; // Store grouped milestone activities
      
        // **Flatten all API responses into a single array of activities**
        const allActivities = this.activities.flat();
      
        allActivities.forEach(activity => {
          const againstId = activity.againstId;
          const againstType = activity.againstType;
          const billableSeconds = parseInt(activity.billable, 10) || 0;
          const dateCreated = activity.dateCreated || 0; // Default to 0 if missing
      
          if (againstType === "issue") {
            if (!issueActivities[againstId]) {
              issueActivities[againstId] = { totalBillable: 0, activities: [] };
            }
            issueActivities[againstId].totalBillable += billableSeconds;
            issueActivities[againstId].activities.push({ ...activity, dateCreated });
          } else if (againstType === "job") {
            if (!jobActivities[againstId]) {
              jobActivities[againstId] = { totalBillable: 0, activities: [] };
            }
            jobActivities[againstId].totalBillable += billableSeconds;
            jobActivities[againstId].activities.push({ ...activity, dateCreated });
          } else if (againstType === "milestone") {
            if (!milestoneActivities[againstId]) {
              milestoneActivities[againstId] = { totalBillable: 0, activities: [] };
            }
            milestoneActivities[againstId].totalBillable += billableSeconds;
            milestoneActivities[againstId].activities.push({ ...activity, dateCreated });
          } else {
            console.warn(`Skipping activity with unknown againstType:`, activity);
          }
        });
      
        // **Sort activities within each issue and job by dateCreated (ascending)**
        for (const issueId in issueActivities) {
          issueActivities[issueId].totalBillable /= 3600;
          console.log("Issue Activities: ",issueActivities);
        }
        for (const jobId in jobActivities) {
          jobActivities[jobId].totalBillable /= 3600;
          console.log("Job Activities: ",jobActivities);
        }
        for (const milestoneId in milestoneActivities) {
          milestoneActivities[milestoneId].totalBillable /= 3600;
          console.log("Milestone Activities: ",milestoneActivities);
        }
      
        this.activitiesByIssue = issueActivities;
        this.activitiesByJob = jobActivities;
        this.activitiesByMilestone = milestoneActivities;
      },
      

    extractIssueIdsFromActivities() {
      return Object.keys(this.activitiesByIssue);
    },
    
    extractJobIdsFromActivities() {
      const jobIds = new Set();
      const milestoneStore = useMilestoneStore(); // ✅ Import milestone store
    
      // ✅ Extract job IDs from activities directly logged against jobs
      Object.keys(this.activitiesByJob).forEach(jobId => {
        jobIds.add(jobId);
      });
    
      // ✅ Extract job IDs from activities against milestones
      Object.keys(this.activitiesByMilestone).forEach(milestoneId => {
        const milestone = milestoneStore.milestones.find(m => m.id == milestoneId);
        if (milestone && milestone.job) {
          jobIds.add(milestone.job);
        }
      });
    
      // ✅ Extract job IDs from milestones even if no activity exists
      milestoneStore.milestones.forEach(milestone => {
        if (milestone.job) {
          jobIds.add(milestone.job);
        }
      });
    
      console.log("Extracted Unique Job IDs:", [...jobIds]);
      return [...jobIds]; // Convert Set back to an array
    },
    
    extractMilestoneIdsFromActivities() {
      return Object.keys(this.activitiesByMilestone);
    }
    
  },

  getters: {
    getActivitiesByAgainstId: (state) => (againstId) => {
      return state.activities.flat().filter((activity) => activity.against_Id === againstId);
    },
    getActivitiesByIssue: (state) => {
      return state.activitiesByIssue;
    },
    getActivitiesByJob: (state) => {
      return state.activitiesByJob;
    },
    getActivitiesByMilestone: (state) => {
      return state.activitiesByMilestone;
    }
  }
});
