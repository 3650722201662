import { defineStore } from "pinia";
import { useAuthStore } from "./authStore";
import { useActivityStore } from "./activityStore";
import axios from "axios";

export const useMilestoneStore = defineStore("milestoneStore", {
  state: () => ({
    milestones: [],
    isLoading: false,
    error: null
  }),

  actions: {
    async fetchMilestonesFromActivities() {
      const apiUrl = process.env.VUE_APP_API_ENDPOINT;
      const authStore = useAuthStore();
      const activityStore = useActivityStore();

      if (!authStore.token) {
        throw new Error("Authentication token is not available.");
      }

      try {
        this.isLoading = true;
        this.milestones = []; // Reset milestones

        // **Extract milestone IDs from activityStore**
        const milestoneIds = Object.keys(activityStore.activitiesByMilestone);
        if (milestoneIds.length === 0) {
          console.warn("No Milestone IDs found in activities.");
          this.isLoading = false;
          return;
        }

        console.log("Fetching milestones for IDs:", milestoneIds.join(","));

        // **Fetch milestones based on extracted milestone IDs**
        const response = await axios.get(`${apiUrl}/milestones`, {
          params: { milestoneId: milestoneIds.join(",") }, // No date filtering
          headers: {
            Authorization: `Bearer ${authStore.token}`
          }
        });

        let milestones = response.data;

        // ✅ **Replace `billable_Seconds` with `totalBillable` from activities**
        milestones = milestones.map(milestone => {
          const milestoneId = milestone.id; // ID of the milestone
          const activityData = activityStore.activitiesByMilestone[milestoneId]; // Get activity data

          if (activityData) {
            milestone.billable_Seconds = Math.round(activityData.totalBillable * 3600); // Convert hours to seconds
          } else {
            milestone.billable_Seconds = 0; // Default to 0 if no matching data
          }

          return milestone;
        });

        this.milestones = milestones;
        console.log("Updated Milestones with Activity Data:", this.milestones);

        this.error = null;
      } catch (err) {
        console.error("Error fetching milestones:", err);
        this.error = err.message;
        this.milestones = [];
      } finally {
        this.isLoading = false;
      }
    },

    clearMilestones() {
      console.log("Clearing milestone data.");
      this.milestones = [];
    }
  },

  getters: {
    getMilestonesById: (state) => (milestoneId) => {
      return state.milestones.filter(milestone => milestone.id === milestoneId);
    }
  }
});
