<template>
  <div class="relative">
    <div class="data-container max-h-96 overflow-auto bg-white shadow rounded-lg work-area-bg">
      <TicketInformation />
    </div>

    <!-- Button to open modal -->
    <div class="flex justify-center pt-2">
      <button 
        @click="openModal" 
        :disabled="!isAuthenticated" 
        :class="{
          'px-4 py-2 bg-gray-500 text-white rounded': true,
          'hover:bg-cyan-500': isAuthenticated,
          'opacity-50 cursor-not-allowed': !isAuthenticated
        }"
      >
        View Report Preview
      </button>
    </div>

    <!-- Modal -->
    <div v-if="isModalOpen" class="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4 z-50">
      <div :class="['bg-white p-6 rounded shadow-lg w-letter overflow-auto', hasRequiredData ? 'h-full' : 'h-auto']">
        <h3 class="text-lg font-bold mb-4 text-center">Report Preview</h3>
        <div v-if="hasRequiredData" id="contentToCapture" ref="contentToCapture" class="bg-white relative work-area-bg">
          <!-- Modal content -->
          <div class="px-7 py-3 flex justify-center relative items-center">
            <img src="../assets/logo.png" alt="Perbyte Logo" class="h-16 mr-4">
            <div class="p-1 mt-1 border-gray-200 w-full">
              <h2 class="text-lg text-center pl-5 font-semibold text-gray-700">Status Report for: {{ selectedCompanyLabel }}</h2>
            </div>
          </div>

          <!-- Modal Body -->
          <div class="p-7">
            <p class="text-md font-medium text-gray-600 mb-2">{{ selectedFrequencyLabel }} reports are generated for your convenience.</p>
            <p class="text-md font-medium text-gray-600 mb-2">Report for the period of: {{ formattedStartDate }} to {{ formattedEndDate }}</p>
            <!-- Ticket Information Component -->
            <div class="mb-4 page-break-inside-avoid">
              <TicketInformation />
            </div>
            <!-- Period Summary Section (Only Inside Modal) -->
            <div class="flex justify-between bg-gray-100 p-4 rounded-lg text-gray-700 font-semibold mb-4">
              <div>Hours Budgeted: <span class="font-bold">{{ formattedTotalBudgeted }}h</span></div>
              <div>Used this Quarter(s): <span class="font-bold">{{ formattedUsedThisQuarter }}h</span></div>
              <div>Used this Date Range: <span class="font-bold">{{ formattedUsedThisPeriod }}h</span></div>
              <div>Hours Remaining: <span class="font-bold">{{ formattedRemainingHours }}h</span></div>
            </div>
            <!-- Chart Display Component -->
            <div class="page-break-inside-avoid" style="width: 800px; height: 600px;">
              <ChartDisplay ref="chartDisplay" />
            </div>
          </div>
        </div>
        <div v-else class="flex items-center justify-center">
          <div class="bg-gray-100 p-4 rounded-lg shadow-lg text-center">
            <p class="text-sm font-medium text-gray-600">Please select a company, frequency, and date range to generate a report.</p>
          </div>
        </div>
        <!-- Close button -->
        <div class="flex justify-center pt-2">
          <button @click="closeModal" class="mx-2 mt-4 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700">
            Close Preview
          </button>
          <button v-if="hasRequiredData" @click="downloadPDF" class="mx-2 mt-4 px-4 py-2 bg-cyan-500 text-white rounded hover:bg-cyan-700">Download Report</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { nextTick, watch } from 'vue';
import html2pdf from 'html2pdf.js';
import TicketInformation from './TicketInformation.vue';
import ChartDisplay from './ChartDisplay.vue';
import { useAuthStore } from '../pinia/authStore';
import { useMyStore } from '../pinia/store';
import { usePeriodStore } from '../pinia/periodStore';

export default {
  name: 'ScrollableDataContainer',
  components: {
    TicketInformation,
    ChartDisplay, 
  },
  setup() {
    const notesStore = useMyStore();
    const authStore = useAuthStore();
    const reportStore = useMyStore();
    const periodStore = usePeriodStore();

    watch(() => notesStore.selectedContract, (newContract) => {
      const contract = notesStore.contracts.find(c => c.id === newContract);
      if (contract && contract.frequency) {
        notesStore.selectedFrequency = contract.frequency.id;
      }
    });

    return { notesStore, authStore, reportStore, periodStore };
  },
  data() {
    return {
      isModalOpen: false,
    };
  },
  computed: {
    myStore() {
      return useMyStore();
    },
    selectedFrequency() {
      return this.myStore.selectedFrequency;
    },
    selectedCompany() {
      return this.myStore.selectedContract;
    },
    startDate() {
      return this.myStore.startDate;
    },
    endDate() {
      return this.myStore.endDate;
    },
    formattedStartDate() {
      return this.myStore.formattedStartDate;
    },
    formattedEndDate() {
      return this.myStore.formattedEndDate;
    },
    selectedFrequencyLabel() {
      return this.myStore.selectedFrequencyLabel;
    },
    selectedCompanyLabel() {
      return this.myStore.selectedContractName;
    },
    formattedTotalBudgeted() {
      return this.periodStore.totalBudgeted.toFixed(2);
    },
    formattedUsedThisQuarter() {
      return this.periodStore.usedThisQuarter.toFixed(2);
    },
    formattedUsedThisPeriod() {
      return this.periodStore.usedThisPeriod.toFixed(2);
    },
    formattedRemainingHours() {
      return this.periodStore.remainingHours.toFixed(2);
    },
    isAuthenticated() {
      return this.authStore.isAuthenticated;
    },
    hasRequiredData() {
      return this.selectedCompany && this.selectedFrequency && this.startDate && this.endDate;
    },
  },
  methods: {
    openModal() {
    this.isModalOpen = true;

      // ✅ Refresh the chart when opening the modal
      this.$nextTick(() => {
        if (this.$refs.chartDisplay) {
          this.$refs.chartDisplay.refreshChart();
        }
      });
    },
    closeModal() {
      this.isModalOpen = false;
    },
    async downloadPDF() {
      const element = this.$refs.contentToCapture;
      const options = {
        margin: [0.5, 0.5, 0.5, 0.5], // Adjust margins as needed
        filename: this.generateFileName(),
        image: { type: 'png', quality: 1 },
        html2canvas: { 
          scale: 4,  // Increasing the scale will increase the resolution of the canvas
          logging: true, // Log progress to the console
          useCORS: true // Enable cross-origin resource sharing
        },
        jsPDF: { 
          unit: 'in', 
          format: 'letter', 
          orientation: 'portrait' 
        },
        pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }
      };

      // Ensure chart is fully rendered before generating PDF
      await nextTick();

      await html2pdf().set(options).from(element).save();

      // Save report parameters to the store
      this.reportStore.setLastReportParams();
    },
    generateFileName() {
      const currentDate = new Date();
      const timestamp = currentDate.toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      });
      return `${timestamp}.pdf`.replace(/\s+/g, '_');
    }
  }
}
</script>

<style scoped>
/* Add the background image */
.work-area-bg {
  background-image: url('@/assets/work_area_background1.png');
  background-size: 120%;
  background-position: left -12rem; 
  background-repeat: no-repeat;
}

/* Style for the Perbyte logo */
.h-16.mr-4 {
  height: 4rem;
  margin-right: 1rem;
}
</style>
