import { defineStore } from "pinia";
import { useAuthStore } from "./authStore";
import { useActivityStore } from "./activityStore";
import { useMilestoneStore } from "./milestoneStore"; // ✅ Import milestone store
import axios from "axios";

export const useProjectStore = defineStore("projectStore", {
  state: () => ({
    projects: [],
    isLoading: false,
    error: null
  }),

  actions: {
    async fetchProjectsFromActivities() {
      const apiUrl = process.env.VUE_APP_API_ENDPOINT;
      const authStore = useAuthStore();
      const activityStore = useActivityStore();
      const milestoneStore = useMilestoneStore(); // ✅ Include milestone store

      if (!authStore.token) {
        throw new Error("Authentication token is not available.");
      }

      try {
        this.isLoading = true;
        this.projects = []; // Reset projects

        // ✅ Extract job IDs from **both activities and milestones**
        const jobIds = new Set(activityStore.extractJobIdsFromActivities()); // Jobs from activities
        const milestoneIds = new Set(activityStore.extractMilestoneIdsFromActivities()); // Milestones with activities

        // ✅ Also extract job IDs from all milestones
        milestoneStore.milestones.forEach(milestone => {
          if (milestone.job) {
            jobIds.add(milestone.job);
          }
        });

        if (jobIds.size === 0) {
          console.warn("No Job IDs found in activities or milestones.");
          this.isLoading = false;
          return;
        }

        console.log("Fetching projects for Jobs:", [...jobIds].join(","));
        console.log("Fetching Milestones:", [...milestoneIds].join(","));

        // ✅ Fetch jobs
        let jobs = [];
        if (jobIds.size > 0) {
          const jobResponse = await axios.get(`${apiUrl}/jobs`, {
            params: { jobId: [...jobIds].join(",") },
            headers: { Authorization: `Bearer ${authStore.token}` }
          });
          jobs = jobResponse.data;
        }

        // ✅ Fetch milestones
        let milestones = [];
        if (milestoneIds.size > 0) {
          const milestoneResponse = await axios.get(`${apiUrl}/milestones`, {
            params: { milestoneId: [...milestoneIds].join(",") },
            headers: { Authorization: `Bearer ${authStore.token}` }
          });
          milestones = milestoneResponse.data;
        }

        // ✅ Organize milestones under their respective jobs
        const jobMap = {}; // Maps job IDs to their projects
        jobs.forEach(job => {
          jobMap[job.id] = {
            id: job.id,
            title: job.title,
            standing: job.standing,
            description: "Project",
            billable_Seconds: 0, // Will be calculated below
            milestones: []
          };
        });

        // ✅ Attach milestones to their parent jobs
        milestones.forEach(milestone => {
          const jobId = milestone.job;
          if (jobMap[jobId]) {
            jobMap[jobId].milestones.push({
              id: milestone.id,
              title: milestone.title,
              standing: milestone.standing,
              billable_Seconds: 0 // Will be calculated below
            });
          } else {
            console.warn(`Orphaned milestone found (Job ID: ${jobId})`, milestone);
          }
        });

        // ✅ Calculate total billable seconds (jobs + their milestones)
        for (const jobId in jobMap) {
          const jobData = jobMap[jobId];

          // Billable time from **Job activities**
          const jobActivityData = activityStore.activitiesByJob[jobId];
          const jobBillableTime = jobActivityData ? jobActivityData.totalBillable : 0;

          // Billable time from **Milestone activities**
          let milestoneBillableTime = 0;
          jobData.milestones.forEach(milestone => {
            const milestoneActivityData = activityStore.activitiesByMilestone[milestone.id];
            const milestoneTime = milestoneActivityData ? milestoneActivityData.totalBillable : 0;

            // Update milestone billable time
            milestone.billable_Seconds = Math.round(milestoneTime * 3600);

            // Add to total milestone billable time for the project
            milestoneBillableTime += milestoneTime;
          });

          // Convert total billable time to seconds & store in the job object
          jobData.billable_Seconds = Math.round((jobBillableTime + milestoneBillableTime) * 3600);
        }

        // ✅ Convert jobMap into an array
        this.projects = Object.values(jobMap);
        console.log("Projects successfully retrieved:", this.projects);

        this.error = null;
      } catch (err) {
        console.error("Error fetching projects:", err);
        this.error = err.message;
        this.projects = [];
      } finally {
        this.isLoading = false;
      }
    },

    clearProjects() {
      console.log("Clearing project data.");
      this.projects = [];
    }
  },

  getters: {
    getProjectsByJobId: (state) => (jobId) => {
      return state.projects.find(project => project.id === jobId);
    },

    getActiveProjects: (state) => {
      return state.projects.filter(project => project.standing === "active");
    },

    getProjectsWithMilestones: (state) => {
      return state.projects.filter(project => project.milestones.length > 0);
    }
  }
});
