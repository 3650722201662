import { defineStore } from "pinia";
import { useAuthStore } from "./authStore";
import { useTicketStore } from "./ticketStore";
import { useActivityStore } from "./activityStore";
import { useMilestoneStore } from "./milestoneStore"; 
import { useProjectStore } from "./projectStore"; // ✅ Import project store
import axios from "axios";

export const usePeriodStore = defineStore("periodStore", {
  state: () => ({
    totalBudgeted: 0,
    usedThisQuarter: 0,
    usedThisPeriod: 0,
    remainingHours: 0,
    maxHours: 10,
    periods: [],
    isLoading: false,
    error: null
  }),

  actions: {
    async fetchPeriods(contractId, startDate, endDate) {
      const apiUrl = process.env.VUE_APP_API_ENDPOINT;
      const authStore = useAuthStore();
      const ticketStore = useTicketStore();
      const activityStore = useActivityStore();
      const milestoneStore = useMilestoneStore();
      const projectStore = useProjectStore(); // ✅ Include project store

      if (!authStore.token) {
        throw new Error("Authentication token is not available.");
      }

      // ✅ Prevent unnecessary API calls
      if (this.periods.length > 0) {
        console.log("Periods already fetched, skipping API call.");
        return;
      }

      try {
        this.isLoading = true;
        console.log(`Fetching periods for contractId: ${contractId}`);

        // ✅ Fetch periods from API
        const response = await axios.get(`${apiUrl}/periods`, {
          params: { contractId, startDate, endDate },
          headers: { Authorization: `Bearer ${authStore.token}` }
        });

        this.periods = response.data;

        if (this.periods.length > 0) {
          console.log("Retrieved Period IDs:", this.periods.map(p => p.id));
        } else {
          console.warn("No periods retrieved for the given contract.");
        }

        this.processPeriodData();

        // ✅ Fetch activities **only if they are not already loaded**
        if (activityStore.activities.length === 0) {
          await activityStore.fetchActivitiesFromPeriods();
        } else {
          console.log("Skipping activity fetching. Data already exists.");
        }

        // ✅ Extract and fetch **tickets**
        const issueIds = activityStore.extractIssueIdsFromActivities();
        if (issueIds.length > 0 && ticketStore.tickets.length === 0) {
          console.log("Extracted Issue IDs from Activities:", issueIds);
          await ticketStore.fetchTicketsFromActivities(issueIds.join(","));
        } else {
          console.log("Skipping ticket fetching. Data already exists.");
        }

        // ✅ Extract and fetch **milestones**
        const milestoneIds = activityStore.extractMilestoneIdsFromActivities();
        if (milestoneIds.length > 0 && milestoneStore.milestones.length === 0) {
          console.log("Extracted Milestone IDs from Activities:", milestoneIds);
          await milestoneStore.fetchMilestonesFromActivities(milestoneIds.join(","));
        } else {
          console.log("Skipping milestone fetching. Data already exists.");
        }

        // ✅ Extract and fetch **projects (jobs)**
        const jobIds = activityStore.extractJobIdsFromActivities();
        if (jobIds.length > 0 && projectStore.projects.length === 0) {
          console.log("Extracted Job IDs from Activities:", jobIds);
          await projectStore.fetchProjectsFromActivities();
        } else {
          console.log("Skipping project fetching. Data already exists.");
        }

        // ✅ Update period usage calculations
        this.setPeriodHoursByTicketsProjectsAndMilestones();

        this.error = null;
      } catch (err) {
        console.error("Error fetching periods:", err);
        this.error = err.message;
        this.periods = [];
      } finally {
        this.isLoading = false;
      }
    },

    clearPeriods() {
      console.log("Clearing period data.");
      this.periods = [];
      this.totalBudgeted = 0;
      this.usedThisQuarter = 0;
      this.usedThisPeriod = 0;
      this.remainingHours = 0;
    },

    processPeriodData() {
      console.log("Processing period data...");
      this.periods.forEach(period => console.log("Period Object:", period));

      const totalSeconds = this.periods.reduce((acc, period) => acc + (period.budget?.value || 0), 0);
      const totalHours = totalSeconds / 3600;
      this.setTotalBudgeted(totalHours);

      const totalUsageSeconds = this.periods.reduce((acc, period) => acc + (period.budget_Used?.value || 0), 0);
      const totalUsageHours = totalUsageSeconds / 3600;
      this.setUsedThisQuarter(totalUsageHours);

      this.setRemainingHours(totalHours - totalUsageHours);
      this.setMaxHours(Math.max(totalHours, totalUsageHours));
    },

    setPeriodHoursByTicketsProjectsAndMilestones() {
      console.log("Calculating period hours by tickets, projects, and milestones...");
    
      const ticketStore = useTicketStore();
      const projectStore = useProjectStore();
    
      // ✅ Sum up billable seconds from **tickets**
      const totalTicketSeconds = ticketStore.tickets.reduce((acc, ticket) => acc + (ticket.billable_Seconds || 0), 0);
      const totalTicketHours = totalTicketSeconds / 3600;
    

      // ✅ Sum up billable seconds from **projects (jobs)**
      const totalProjectSeconds = projectStore.projects.reduce((acc, project) => acc + (project.billable_Seconds || 0), 0);
      const totalProjectHours = totalProjectSeconds / 3600;
    
      const totalBillableHours = totalTicketHours + totalProjectHours;
    
      console.log(`Total Billable Hours this period: ${totalBillableHours}`);
    
      this.setUsedThisPeriod(totalBillableHours);
      this.setMaxHours(Math.max(totalBillableHours, this.maxHours));
    },

    extractIssueIdsFromActivities() {
      console.log("Extracting Issue IDs from Activities...");
      const activityStore = useActivityStore();
      return activityStore.extractIssueIdsFromActivities();
    },

    setTotalBudgeted(hours) { this.totalBudgeted = hours; },
    setUsedThisQuarter(hours) { this.usedThisQuarter = hours; },
    setUsedThisPeriod(hours) { this.usedThisPeriod = hours; },
    setRemainingHours(hours) { this.remainingHours = hours; },
    setMaxHours(hours) { this.maxHours = Math.round(hours / 5) * 5; }
  }
});
